import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { useResponsive } from "../../hooks/useResponsive";
import { Project } from "../../shared/contentful/contentful.types";

import "./portfolio.scss";
import ScopesDesktop from "./ScopesDesktop.component";
import ScopesMobile from "./ScopesMobile.component";

const PortfolioCard = ({
  title,
  thumbnail,
  client,
  motionCapture,
  animation,
  cinematics,
  gameAudio,
  talentManagement,
}: Project) => {
  const image = getImage(thumbnail as any);

  const scopes = [
    { name: "mocap", content: motionCapture },
    { name: "anim", content: animation },
    { name: "cine", content: cinematics },
    { name: "audio", content: gameAudio },
    { name: "talent", content: talentManagement },
  ];

  return (
    <div className={"layout-grid portfolio-card-wrapper"}>
      <div className="thumbnail-wrapper">
        {image && <GatsbyImage image={image} alt={title} />}
        <ScopesDesktop scopes={scopes} />
      </div>
      <div className="portfolio-content-wrapper">
        <h2 className={"portfolio-title"}>{title}</h2>
        {client && (
          <div className={"client-wrapper"}>
            <p className={"title"}>client</p>
            <hr />
            <p className={"name"}>{client}</p>
          </div>
        )}
      </div>
      <ScopesMobile scopes={scopes} />
    </div>
  );
};
export default PortfolioCard;
