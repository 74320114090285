import { BLOCKS } from "@contentful/rich-text-types";
import React, { ReactNode } from "react";
import { JsonContent } from "../../shared/contentful/contentful.types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import "./portfolio.scss";

type Props = {
  intro: JsonContent;
  className: string;
};

const renderOptions = (className: string) => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
        <p className={className}>{children}</p>
      ),
    },
  };
};

const PortfolioIntro = ({ intro, className }: Props) => {
  return <>{renderRichText(intro, renderOptions(className))}</>;
};
export default PortfolioIntro;
