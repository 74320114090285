import React from "react";
import { Plus } from "react-feather";

import "./portfolio.scss";

type Props = {
  loadMore: () => void;
  content: string;
};

const LoadMore = ({ loadMore, content }: Props) => {
  return (
    <button className="show-more" onClick={loadMore}>
      <span>
        <Plus />
        <span>{content}</span>
      </span>
    </button>
  );
};
export default LoadMore;
