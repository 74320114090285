import { Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import { useResponsiveLayout } from "../../hooks/useResponsive";

import "./scopes.scss";

export type Scope = {
  name: string;
  content: string[] | null;
};

type Props = {
  scopes: Scope[];
};

const ScopesDesktop = ({ scopes }: Props) => {
  const { showCarouselContent } = useResponsiveLayout();
  return (
    <>
      {showCarouselContent && (
        <div className="overlay-wrapper">
          <p className="scopes-title">
            <Trans>scope of work</Trans>
          </p>
          <div className="scopes-wrapper">
            {scopes.map((s) => {
              return (
                s.content && (
                  <div className="scope-wrapper">
                    <p className="scope-name">
                      <Trans>{s.name}</Trans>
                    </p>
                    {s.content.map((c) => (
                      <p className="scope-content">
                        <Trans>{c}</Trans>
                      </p>
                    ))}
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default ScopesDesktop;
