import React, { useEffect, useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { Scope } from "./ScopesDesktop.component";
import { useResponsive, useResponsiveLayout } from "../../hooks/useResponsive";
import { Trans } from "gatsby-plugin-react-i18next";

const { colors } = require("../../shared/style/colors");

import "./scopes.scss";
import { ArrowLeft, ArrowRight } from "react-feather";

type Props = {
  scopes: Scope[];
};

const ScopesMobile = ({ scopes }: Props) => {
  const { isSmall } = useResponsive();
  const { showCarouselContent } = useResponsiveLayout();
  const displayCarousel = !showCarouselContent;

  const [showArrows, setShowArrows] = useState(false);
  const [visibleSlides, setVsibleSlides] = useState(2);
  const displayedScopes = scopes.filter((s) => s.content);
  const numOfSlides = displayedScopes.length;

  useEffect(() => {
    if (isSmall) {
      setVsibleSlides(3);
      numOfSlides > 3 && setShowArrows(true);
    } else {
      numOfSlides > 2 && setShowArrows(true);
    }
  }, []);

  return (
    <>
      {displayCarousel && (
        <div className="scopes-mobile-wrapper">
          <p className="scopes-mobile-title">
            <Trans>scope of work</Trans>
          </p>
          <CarouselProvider
            naturalSlideHeight={300}
            naturalSlideWidth={50}
            isIntrinsicHeight={true}
            visibleSlides={visibleSlides}
            totalSlides={numOfSlides}
            className={"scope-carousel-wrapper"}
          >
            <Slider>
              {scopes.map(
                (scope, index) =>
                  scope.content && (
                    <Slide key={index} index={index}>
                      <p className={"scope-name"}>
                        <Trans>{scope.name}</Trans>
                      </p>
                      {scope.content.map((c, i) => (
                        <p className="scope-content" key={i}>
                          <Trans>{c}</Trans>
                        </p>
                      ))}
                    </Slide>
                  )
              )}
            </Slider>
            {showArrows && (
              <div className="arrows-wrapper">
                <ButtonBack>
                  <span>
                    <ArrowLeft size={16} color={colors.offBlack} />
                  </span>
                </ButtonBack>
                <ButtonNext>
                  <span>
                    <ArrowRight size={16} color={colors.offBlack} />
                  </span>
                </ButtonNext>
              </div>
            )}
          </CarouselProvider>
        </div>
      )}
    </>
  );
};
export default ScopesMobile;
