import { graphql, PageProps } from "gatsby";
import React, { createContext, useEffect, useState } from "react";
import PortfolioNavigation from "../components/portfolio/PortfolioNavigation.component";
import { Layout } from "../components/layout/Layout.component";
import LoadMore from "../components/portfolio/LoadMore.component";
import PortfolioCard from "../components/portfolio/PortfolioCard.component";
import PortfolioIntro from "../components/portfolio/PortfolioIntro.component";
import { useResponsive } from "../hooks/useResponsive";
import {
  ContentfulTypenames,
  JsonContent,
  Project,
} from "../shared/contentful/contentful.types";
import { PageContext, SEOContent } from "../shared/page.types";
import { CallToActionData } from "../components/call-to-action/call-to-action.types";

type PortfolioPageProps = {
  pageData: {
    title: string;
    content: (Project | CallToActionData)[];
    intro: JsonContent;
    seo: SEOContent;
  };
};

export const PortfolioContext = createContext<PageContext>({
  title: "",
  children: <></>,
});

const PortfolioPage = ({
  data,
  location: { href },
}: PageProps<PortfolioPageProps>) => {
  const {
    pageData: { title, content, intro, seo },
  } = data;

  const [contextValue, setContextValue] = useState<PageContext>({
    title: "",
    children: <></>,
  });

  const { isPortrait, isLarge } = useResponsive();
  const displayTitle = isPortrait && !isLarge;

  const cta = content.find(
    (c) => c.__typename === ContentfulTypenames.CallToAction
  ) as CallToActionData;
  const cards = content.filter(
    (c) => c.__typename === ContentfulTypenames.Project
  ) as Project[];

  const [cardsTreshold, setCardsTreshold] = useState(10);
  const [canShowMore, setCanShowMore] = useState(true);

  const loadMore = () => {
    setCardsTreshold(cardsTreshold + 10);
  };

  useEffect(() => {
    if (cardsTreshold > content.length) {
      setCanShowMore(false);
    }
    return () => {
      setCanShowMore(true);
    };
  }, [cardsTreshold]);

  useEffect(() => {
    setContextValue({
      title,
      children: <PortfolioIntro intro={intro} className={"desktop-intro"} />,
    });
  }, []);

  const seoProps = { ...seo, url: href };

  return (
    <PortfolioContext.Provider value={contextValue}>
      <Layout pageNavigation={PortfolioNavigation} seo={seoProps}>
        {displayTitle && (
          <div className="boxed-container">
            <PortfolioIntro intro={intro} className={"portfolio-intro"} />
          </div>
        )}
        <div className="portfolio-cards-wrapper">
          {cards.slice(0, cardsTreshold).map((project) => (
            <PortfolioCard {...project} key={project.title} />
          ))}
        </div>
        {canShowMore && (
          <LoadMore loadMore={loadMore} content={cta.ctaContent} />
        )}
      </Layout>
    </PortfolioContext.Provider>
  );
};
export default PortfolioPage;

export const query = graphql`
  query portfolioQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutAllPages(
      identifier: { eq: "page_portfolio" }
      node_locale: { eq: $language }
    ) {
      title
      intro {
        raw
      }
      content {
        ...project
        ...callToAction
      }
      seo {
        ...seo
      }
    }
  }
`;
